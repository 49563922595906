"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const axios_1 = require("axios");
const languageItem_1 = require("../languageSwitch/languageItem");
class LanguageService {
    constructor() { }
    static async GetLanguageElements(pageKey, isoCode) {
        let languageResults = [];
        const apiUrl = "/umbraco/rentschler/Language/GetLanguageItems";
        const response = await axios_1.default.get(apiUrl, {
            params: {
                pageGuid: pageKey,
                isoCode: isoCode
            }
        });
        if (response && response.data) {
            response.data.forEach(r => {
                languageResults.push(new languageItem_1.default(r));
            });
        }
        return languageResults;
    }
}
exports.default = LanguageService;
