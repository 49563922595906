"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const emitter_1 = require("../../emitter/emitter");
const dictionaryService_1 = require("../../services/dictionaryService");
const searchbar_vue_1 = require("./searchbar.vue");
const helperFunctions_1 = require("../../services/helperFunctions");
const searchbarApp = document.getElementById("searchbarElement");
if (searchbarApp) {
    const searchUrl = searchbarApp.dataset["search"];
    const app = (0, vue_1.createApp)(searchbar_vue_1.default, { searchUrl: searchUrl });
    const emitter = emitter_1.emitterInstance;
    app.config.globalProperties.emitter = emitter;
    const language = (0, helperFunctions_1.getLanguage)();
    (0, dictionaryService_1.GetItems)("VUE_Search", language).then((dict) => {
        app.config.globalProperties.dict = dict;
        app.mount('#searchbarElement');
    });
}
