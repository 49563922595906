"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const newsFilter_1 = require("../newsFilter/newsFilter");
const teaserCard_vue_1 = require("../layoutElements/teaserCard.vue");
const newsEventFilterService_1 = require("../services/newsEventFilterService");
const newsService_1 = require("../services/newsService");
const helperFunctions_1 = require("../services/helperFunctions");
const FilteredNewsOverview = (0, vue_1.defineComponent)({
    name: "Filtered NewsOverview",
    props: {
        startNode: {
            type: String,
            default: "",
        },
        count: {
            type: Number,
            default: 20,
        }
    },
    data() {
        return {
            news: [],
            more: false,
            filteredNews: [],
            dictionary: this.dict,
            version: 0,
            currentFilter: {},
            currentServiceElement: {},
        };
    },
    components: {
        TeaserCard: teaserCard_vue_1.default,
    },
    async mounted() {
        this.currentServiceElement = newsEventFilterService_1.NewsEventFilterService.LoadServiceElement(this.startNode, this.count, "news");
        this.currentFilter = newsEventFilterService_1.NewsEventFilterService.LoadFilter(this.startNode);
        await this.getNews(this.currentServiceElement.start, this.currentServiceElement.count);
        this.emitter.on("changeFilter", async (result) => {
            if (result) {
                this.currentFilter = result;
                await this.getNews(0, this.count);
            }
        });
    },
    beforeUnmount() {
        this.emitter.off("changeFilter");
    },
    methods: {
        //add für reload vorbereiter
        async getNews(start, count, add = false) {
            const filter = this.currentFilter;
            const filteredRequest = new newsFilter_1.FilteredRequest({
                startNode: filter.startNode,
                start: start,
                count: count,
                sort: filter.sorted,
                location: filter.location,
                language: (0, helperFunctions_1.getLanguage)()
            });
            const newsResult = await newsService_1.NewsService.GetNewsElements(filteredRequest);
            const newsElements = add ? this.news : [];
            this.news = [...new Set(newsElements.concat(newsResult.news))];
            this.more = newsResult.more;
            this.filterNews();
            if (add) {
                const neServiceElement = new newsFilter_1.NewsEventServiceElement({
                    count: this.news.length
                });
                newsEventFilterService_1.NewsEventFilterService.SaveServiceElement(this.startNode, "news", neServiceElement);
            }
        },
        filterNews() {
            const filter = this.currentFilter;
            let filtered = [];
            this.news.forEach((newsItem) => {
                const isType = filter.type && filter.type.length > 0 ? newsItem.type == filter.type : true;
                const index = filtered.map(n => n.key).indexOf(newsItem.key);
                if (isType && index < 0) {
                    filtered.push(newsItem);
                }
            });
            this.filteredNews = [...new Set(filtered)];
        }
    },
});
exports.default = FilteredNewsOverview;
