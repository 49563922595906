"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FilteredRequest = exports.NewsEventServiceElement = exports.NewsFilterElement = exports.NewsFilterValue = void 0;
class NewsFilterValue {
    sorted;
    type;
    location;
    startNode;
    constructor(item) {
        this.sorted = item.sorted ?? "";
        this.type = item.type ?? "";
        this.location = item.location ?? "";
        this.startNode = item.startNode ?? "";
    }
}
exports.NewsFilterValue = NewsFilterValue;
class NewsFilterElement {
    name;
    value;
    key;
    constructor(item) {
        this.name = item.name ?? "";
        this.value = item.value ?? "";
        this.key = item.key ?? "";
    }
}
exports.NewsFilterElement = NewsFilterElement;
class NewsEventServiceElement {
    start;
    count;
    constructor(item) {
        this.start = item.start ?? 0;
        this.count = item.count ? (item.count > 0 ? item.count : 20) : 20;
    }
}
exports.NewsEventServiceElement = NewsEventServiceElement;
class FilteredRequest {
    startNode;
    start;
    count;
    sort;
    language;
    location;
    overviewNode;
    constructor(item) {
        this.startNode = item.startNode ?? "";
        this.start = item.start ?? 0;
        this.count = item.count ? (item.count > 0 ? item.count : 20) : 20;
        this.sort = item.sort ?? "";
        this.language = item.language ?? "";
        this.location = item.location ?? "";
        this.overviewNode = item.overviewNode ?? "00000000-0000-0000-0000-000000000000";
    }
}
exports.FilteredRequest = FilteredRequest;
