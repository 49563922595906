"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.render = void 0;
const vue_1 = require("vue");
const _hoisted_1 = { class: "news-filter-list row" };
function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_teaser_card = (0, vue_1.resolveComponent)("teaser-card");
    return ((0, vue_1.openBlock)(), (0, vue_1.createElementBlock)(vue_1.Fragment, null, [
        (0, vue_1.createElementVNode)("div", _hoisted_1, [
            ((0, vue_1.openBlock)(true), (0, vue_1.createElementBlock)(vue_1.Fragment, null, (0, vue_1.renderList)(_ctx.filteredNews, (newsItem) => {
                return ((0, vue_1.openBlock)(), (0, vue_1.createBlock)(_component_teaser_card, {
                    key: _ctx.version,
                    image: newsItem.teaserImage + '&quality=100',
                    imageMobile: newsItem.teaserImageMobile + '&quality=100',
                    teaserText: newsItem.teaserText,
                    url: newsItem.url,
                    alt: newsItem.alt,
                    buttonText: _ctx.dictionary['More'],
                    date: newsItem.date
                }, null, 8 /* PROPS */, ["image", "imageMobile", "teaserText", "url", "alt", "buttonText", "date"]));
            }), 128 /* KEYED_FRAGMENT */))
        ]),
        (_ctx.more)
            ? ((0, vue_1.openBlock)(), (0, vue_1.createElementBlock)("button", {
                key: 0,
                class: "btn yellow",
                onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.getNews(_ctx.news.length, _ctx.count, true)))
            }, (0, vue_1.toDisplayString)(_ctx.dictionary['LoadMore']), 1 /* TEXT */))
            : (0, vue_1.createCommentVNode)("v-if", true)
    ], 64 /* STABLE_FRAGMENT */));
}
exports.render = render;
