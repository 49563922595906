"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const dictionaryService_1 = require("../services/dictionaryService");
const emitter_1 = require("../emitter/emitter");
const helperFunctions_1 = require("../services/helperFunctions");
const homepageEventsOverview_vue_1 = require("./homepageEventsOverview.vue");
const emitter = emitter_1.emitterInstance;
const homepageEventsOverviewApp = document.getElementById("eventsOverviewHomepage");
if (homepageEventsOverviewApp) {
    const language = (0, helperFunctions_1.getLanguage)();
    const startNode = homepageEventsOverviewApp.dataset["node"];
    const count = homepageEventsOverviewApp.dataset["items"];
    const overviewNode = homepageEventsOverviewApp.dataset["overview"];
    const app = (0, vue_1.createApp)(homepageEventsOverview_vue_1.default, { startNode: startNode,
        count: Number(count),
        overviewNode: overviewNode });
    app.config.globalProperties.emitter = emitter;
    app.config.globalProperties.language = language;
    (0, dictionaryService_1.GetItems)("VUE_NewsEventsOverviewApp", language).then((dict) => {
        app.config.globalProperties.dict = dict;
        app.mount('#eventsOverviewHomepage');
    });
}
