"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.render = void 0;
const vue_1 = require("vue");
const _hoisted_1 = {
    key: 0,
    class: "searchResult"
};
const _hoisted_2 = { class: "headline" };
const _hoisted_3 = { class: "description" };
const _hoisted_4 = ["href"];
function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_ctx.searchResult)
        ? ((0, vue_1.openBlock)(), (0, vue_1.createElementBlock)("div", _hoisted_1, [
            (0, vue_1.createElementVNode)("div", _hoisted_2, [
                (0, vue_1.createElementVNode)("h4", null, (0, vue_1.toDisplayString)(_ctx.searchResult.title), 1 /* TEXT */)
            ]),
            (0, vue_1.createElementVNode)("div", _hoisted_3, [
                (0, vue_1.createElementVNode)("p", null, (0, vue_1.toDisplayString)(_ctx.searchResult.description), 1 /* TEXT */)
            ]),
            (0, vue_1.createElementVNode)("p", null, [
                (0, vue_1.createElementVNode)("a", {
                    href: _ctx.searchResult.url,
                    class: "btn yellow"
                }, (0, vue_1.toDisplayString)(_ctx.dict["More"]), 9 /* TEXT, PROPS */, _hoisted_4)
            ])
        ]))
        : (0, vue_1.createCommentVNode)("v-if", true);
}
exports.render = render;
