"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const languageService_1 = require("../services/languageService");
const LanguageSwitch = (0, vue_1.defineComponent)({
    name: "Language Switch",
    data() {
        return {
            langItems: [],
        };
    },
    props: {
        currentPage: {
            type: String,
            default: "",
        },
        isoCode: {
            type: String,
            default: "",
        }
    },
    methods: {
        async GetLanguageItems() {
            this.langItems = await languageService_1.default.GetLanguageElements(this.currentPage, this.isoCode);
        },
        checkIndex(index) {
            return index > 0;
        },
        resetStorage() {
            sessionStorage.clear();
        }
    },
    async mounted() {
        await this.GetLanguageItems();
    }
});
exports.default = LanguageSwitch;
