"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EventsResult = exports.Event = void 0;
class Event {
    key;
    title;
    type;
    startDate;
    endDate;
    postDate;
    location;
    views;
    url;
    target;
    constructor(item) {
        this.key = item.key ?? "";
        this.title = item.title ?? "";
        this.type = item.type ?? "event";
        this.startDate = item.startDate ?? new Date();
        this.endDate = item.endDate ?? null;
        this.postDate = item.postDate ?? null;
        this.location = item.location ?? "";
        this.views = item.views ?? 1;
        this.url = item.url ?? "";
        this.target = item.target ?? "";
    }
}
exports.Event = Event;
class EventsResult {
    more;
    events;
    overview;
    constructor(item) {
        this.more = item.more ?? false;
        this.overview = item.overview ?? "#";
        this.events = [];
        if (item.events && item.events.length > 0) {
            item.events.forEach(element => {
                this.events.push(new Event(element));
            });
        }
    }
}
exports.EventsResult = EventsResult;
