"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const searchService_1 = require("../../services/searchService");
const searchElements_1 = require("../searchElements");
const closeButton_vue_1 = require("../../layoutElements/closeButton.vue");
const helperFunctions_1 = require("../../services/helperFunctions");
const SearchbarNatural = (0, vue_1.defineComponent)({
    name: "Searchbar Natural",
    props: {
        count: {
            type: Number,
            default: 20,
        }
    },
    data() {
        return {
            dict: this.dict,
            searchterm: "",
            showOverlaySearchterm: false,
            showSuggestions: false,
            suggestions: [],
        };
    },
    mounted() {
        const searchElement = searchService_1.SearchService.LoadSearch();
        if (searchElement) {
            this.searchterm = searchElement.searchTerm;
        }
    },
    methods: {
        toggleOverlay(value) {
            this.showOverlaySearchterm = value;
        },
        sendSearch() {
            const searchElement = new searchElements_1.SearchElement({ searchTerm: this.searchterm, count: this.count });
            this.emitter.emit("changeSearch", searchElement);
            searchService_1.SearchService.SaveSearch(searchElement);
            this.toggleOverlay(false);
        },
        keyupEvent(event) {
            if (event.key == "Enter") {
                this.sendSearch();
            }
            else {
                this.getSuggestions();
            }
        },
        getSuggestions() {
            if (this.searchterm.length >= 3) {
                searchService_1.SearchService.GetSuggestions(this.searchterm).then((suggestions) => {
                    if (suggestions && suggestions.length > 0) {
                        this.suggestions = suggestions;
                        this.showSuggestions = true;
                    }
                    else {
                        this.showSuggestions = false;
                        this.suggestions = [];
                    }
                });
            }
            else {
                this.suggestions = [];
                this.showSuggestions = false;
            }
        },
        selectSuggestion(suggestion) {
            this.searchterm = suggestion;
            this.suggestions = [];
            this.showSuggestions = false;
            this.sendSearch();
        },
        getSearchSuggestionString(suggestion) {
            return (0, helperFunctions_1.getSearchSuggestionString)(suggestion, this.searchterm);
        }
    },
    components: {
        CloseButton: closeButton_vue_1.default,
    },
});
exports.default = SearchbarNatural;
