"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetItems = void 0;
const axios_1 = require("axios");
const apiUrl = "/umbraco/api/dictionary/getItems";
const dictionaries = [];
async function GetItems(area = "VUE", language = "de-DE") {
    const localDict = dictionaries.find((v, i) => v.area == area && v.language == language);
    if (localDict)
        return localDict.values;
    const response = await axios_1.default.get(apiUrl, {
        params: {
            area: area,
            language: language
        }
    });
    dictionaries.push({ area, language, values: response.data });
    return response.data;
}
exports.GetItems = GetItems;
