"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.render = void 0;
const vue_1 = require("vue");
const _hoisted_1 = { key: 0 };
const _hoisted_2 = ["href", "data-lang"];
const _hoisted_3 = ["data-lang"];
function render(_ctx, _cache, $props, $setup, $data, $options) {
    return ((0, vue_1.openBlock)(true), (0, vue_1.createElementBlock)(vue_1.Fragment, null, (0, vue_1.renderList)(_ctx.langItems, (item, index) => {
        return ((0, vue_1.openBlock)(), (0, vue_1.createElementBlock)(vue_1.Fragment, null, [
            (_ctx.checkIndex(index))
                ? ((0, vue_1.openBlock)(), (0, vue_1.createElementBlock)("span", _hoisted_1, " | "))
                : (0, vue_1.createCommentVNode)("v-if", true),
            (!item.isSelected)
                ? ((0, vue_1.openBlock)(), (0, vue_1.createElementBlock)("a", {
                    key: 1,
                    class: "lang-selectable",
                    href: item.url,
                    "data-lang": item.isoCode,
                    onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.resetStorage()))
                }, (0, vue_1.toDisplayString)(item.langCode), 9 /* TEXT, PROPS */, _hoisted_2))
                : (0, vue_1.createCommentVNode)("v-if", true),
            (item.isSelected)
                ? ((0, vue_1.openBlock)(), (0, vue_1.createElementBlock)("span", {
                    key: 2,
                    class: "lang-selected",
                    "data-lang": item.isoCode
                }, (0, vue_1.toDisplayString)(item.langCode), 9 /* TEXT, PROPS */, _hoisted_3))
                : (0, vue_1.createCommentVNode)("v-if", true)
        ], 64 /* STABLE_FRAGMENT */));
    }), 256 /* UNKEYED_FRAGMENT */));
}
exports.render = render;
