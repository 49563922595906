"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const newsFilter_1 = require("../newsFilter/newsFilter");
const teaserCard_vue_1 = require("../layoutElements/teaserCard.vue");
const newsService_1 = require("../services/newsService");
const FilteredNewsOverview = (0, vue_1.defineComponent)({
    name: "Filtered NewsOverview",
    props: {
        startNode: {
            type: String,
            default: "",
        },
        count: {
            type: Number,
            default: 8,
        },
        overviewNode: {
            type: String,
            default: "",
        }
    },
    data() {
        return {
            news: [],
            more: false,
            dictionary: this.dict,
            version: 0,
            overviewUrl: "#",
            lang: this.language,
        };
    },
    components: {
        TeaserCard: teaserCard_vue_1.default,
    },
    async mounted() {
        await this.getNews();
    },
    methods: {
        //add für reload vorbereiter
        async getNews(add = false) {
            const filteredRequest = new newsFilter_1.FilteredRequest({
                startNode: this.startNode,
                start: 0,
                count: this.count,
                overviewNode: this.overviewNode,
                language: this.lang
            });
            const newsResult = await newsService_1.NewsService.GetNewsElements(filteredRequest);
            const newsElements = [];
            this.news = [...new Set(newsElements.concat(newsResult.news))];
            this.more = newsResult.more;
            this.overviewUrl = newsResult.overview;
        },
    },
});
exports.default = FilteredNewsOverview;
