"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NewsResult = exports.News = void 0;
class News {
    key;
    title;
    teaserText;
    teaserImage;
    alt;
    teaserImageMobile;
    type;
    date;
    location;
    views;
    url;
    target;
    constructor(item) {
        this.key = item.key ?? "";
        this.title = item.title ?? "";
        this.teaserText = item.teaserText ?? "";
        this.teaserImage = item.teaserImage ?? "";
        this.alt = item.alt ?? "";
        this.teaserImageMobile = item.teaserImageMobile ?? "";
        this.type = item.type ?? "event";
        this.date = item.date ?? new Date();
        this.location = item.location ?? "";
        this.views = item.views ?? 1;
        this.url = item.url ?? "";
        this.target = item.target ?? "";
    }
}
exports.News = News;
class NewsResult {
    more;
    news;
    overview;
    constructor(item) {
        this.more = item.more ?? false;
        this.overview = item.overview ?? "#";
        this.news = [];
        if (item.news && item.news.length > 0) {
            item.news.forEach(element => {
                this.news.push(new News(element));
            });
        }
    }
}
exports.NewsResult = NewsResult;
