"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const searchService_1 = require("../../services/searchService");
const searchElements_1 = require("../searchElements");
const helperFunctions_1 = require("../../services/helperFunctions");
const Searchbar = (0, vue_1.defineComponent)({
    name: "Searchbar Header",
    props: {
        searchUrl: {
            type: String,
            default: "#",
        }
    },
    data() {
        return {
            dictionary: this.dict,
            searchTerm: "",
            showSuggestions: false,
            suggestions: [],
        };
    },
    methods: {
        sendSearch() {
            const searchElement = new searchElements_1.SearchElement({ searchTerm: this.searchTerm });
            searchService_1.SearchService.SaveSearch(searchElement);
            let searchUrl = location.origin + this.searchUrl;
            location.href = searchUrl;
        },
        keyupEvent(event) {
            if (event.key == "Enter") {
                this.sendSearch();
            }
            else {
                this.getSuggestions();
            }
        },
        getSuggestions() {
            if (this.searchTerm.length >= 3) {
                searchService_1.SearchService.GetSuggestions(this.searchTerm).then((suggestions) => {
                    if (suggestions && suggestions.length > 0) {
                        this.suggestions = suggestions;
                        this.showSuggestions = true;
                    }
                    else {
                        this.showSuggestions = false;
                        this.suggestions = [];
                    }
                });
            }
            else {
                this.showSuggestions = false;
                this.suggestions = [];
            }
        },
        selectSuggestion(suggestion) {
            this.searchTerm = suggestion;
            this.suggestions = [];
            this.showSuggestions = false;
            this.sendSearch();
        },
        getSearchSuggestionString(suggestion) {
            return (0, helperFunctions_1.getSearchSuggestionString)(suggestion, this.searchTerm);
        }
    }
});
exports.default = Searchbar;
