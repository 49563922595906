"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const dictionaryService_1 = require("../services/dictionaryService");
const emitter_1 = require("../emitter/emitter");
const newsFilter_vue_1 = require("./newsFilter.vue");
const helperFunctions_1 = require("../services/helperFunctions");
const filterApp = document.getElementById("newsFilter");
if (filterApp) {
    const startNode = filterApp.dataset["node"];
    const app = (0, vue_1.createApp)(newsFilter_vue_1.default, { startNode: startNode });
    const emitter = emitter_1.emitterInstance;
    app.config.globalProperties.emitter = emitter;
    const language = (0, helperFunctions_1.getLanguage)();
    (0, dictionaryService_1.GetItems)("VUE_FilterApp", language).then((dict) => {
        app.config.globalProperties.dict = dict;
        app.mount('#newsFilter');
    });
}
