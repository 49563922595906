"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSearchSuggestionString = exports.getLanguage = exports.GetDateTillLastWeek = exports.GetDayString = exports.GetYearMonthString = exports.GetDateString = void 0;
function GetDateString(date) {
    const lang = getLanguage();
    const dateOptions = {
        day: 'numeric',
        weekday: 'long',
        month: 'long',
        year: 'numeric'
    };
    return new Date(date).toLocaleDateString(lang, dateOptions);
}
exports.GetDateString = GetDateString;
function GetYearMonthString(date, lang = "de-DE") {
    const dateOptions = {
        month: 'short',
        year: '2-digit'
    };
    return new Date(date).toLocaleDateString(lang, dateOptions);
}
exports.GetYearMonthString = GetYearMonthString;
function GetDayString(date, lang = "de-DE") {
    const dateOptions = {
        day: 'numeric'
    };
    return new Date(date).toLocaleDateString(lang, dateOptions);
}
exports.GetDayString = GetDayString;
function GetDateTillLastWeek(date, lang, dict) {
    let today = new Date();
    today.setHours(0, 0, 0, 0);
    let yesterday = new Date();
    yesterday.setHours(0, 0, 0, 0);
    yesterday.setDate(yesterday.getDate() - 1);
    let lastWeek = new Date();
    lastWeek.setDate(lastWeek.getDate() - 7);
    lastWeek.setHours(0, 0, 0, 0);
    let compareDate = new Date(date);
    compareDate.setHours(0, 0, 0, 0);
    if (compareDate == today) {
        return dict["Today"];
    }
    if (compareDate == yesterday) {
        return dict["Yesterday"];
    }
    if (compareDate >= lastWeek) {
        return dict["LastWeek"];
    }
    return GetDateString(date);
}
exports.GetDateTillLastWeek = GetDateTillLastWeek;
function getLanguage() {
    let language = document.getElementsByTagName("html")[0].getAttribute("lang");
    language = language && language == "en" ? "en-US" : "de-DE";
    return language;
}
exports.getLanguage = getLanguage;
function getSearchSuggestionString(suggesiton, searchTerm) {
    const index = suggesiton.toLowerCase().indexOf(searchTerm.toLowerCase());
    const subA = suggesiton.substring(0, index);
    const subB = suggesiton.substring(index + searchTerm.length);
    return subA + '<span class="fit">' + searchTerm + '</span>' + subB;
}
exports.getSearchSuggestionString = getSearchSuggestionString;
