"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const newsFilter_1 = require("../newsFilter/newsFilter");
const teaserCard_vue_1 = require("../layoutElements/teaserCard.vue");
const eventService_1 = require("../services/eventService");
const helperFunctions_1 = require("../services/helperFunctions");
const newsEventFilterService_1 = require("../services/newsEventFilterService");
const FilteredEventsOverview = (0, vue_1.defineComponent)({
    name: "Filter EventsOverview",
    props: {
        startNode: {
            type: String,
            default: "",
        },
        count: {
            type: Number,
            default: 20,
        }
    },
    data() {
        return {
            events: [],
            more: false,
            filteredEvents: [],
            dictionary: this.dict,
            lang: this.language,
            currentFilter: {},
            currentServiceElement: {},
        };
    },
    components: {
        TeaserCard: teaserCard_vue_1.default,
    },
    async mounted() {
        this.currentServiceElement = newsEventFilterService_1.NewsEventFilterService.LoadServiceElement(this.startNode, this.count, "events");
        this.currentFilter = newsEventFilterService_1.NewsEventFilterService.LoadFilter(this.startNode);
        await this.getEvents(this.currentServiceElement.start, this.currentServiceElement.count);
        this.emitter.on("changeFilter", async (result) => {
            if (result) {
                this.currentFilter = result;
                await this.getEvents(0, this.count);
            }
        });
    },
    beforeUnmount() {
        this.emitter.off("changeFilter");
    },
    methods: {
        //add für reload vorbereiter
        async getEvents(start, count, add = false) {
            const filter = this.currentFilter;
            const filteredRequest = new newsFilter_1.FilteredRequest({
                startNode: filter.startNode,
                start: start,
                count: count,
                sort: filter.sorted,
                location: filter.location,
                language: (0, helperFunctions_1.getLanguage)()
            });
            const eventsResult = await eventService_1.EventService.GetEventElements(filteredRequest);
            const eventElements = add ? this.events : [];
            this.events = [...new Set(eventElements.concat(eventsResult.events))];
            this.more = eventsResult.more;
            this.filterEvents();
            if (add) {
                const neServiceElement = new newsFilter_1.NewsEventServiceElement({
                    count: this.events.length
                });
                newsEventFilterService_1.NewsEventFilterService.SaveServiceElement(this.startNode, "events", neServiceElement);
            }
        },
        filterEvents() {
            const filter = this.currentFilter;
            let filtered = [];
            this.events.forEach((eventItem) => {
                const isType = filter.type && filter.type.length > 0 ? eventItem.type == filter.type : true;
                const index = filtered.map(n => n.key).indexOf(eventItem.key);
                if (isType && index < 0) {
                    filtered.push(eventItem);
                }
            });
            this.filteredEvents = [...new Set(filtered)];
        },
        getDateString(date) {
            return (0, helperFunctions_1.GetDateString)(date);
        },
        getPostDateString(date) {
            return (0, helperFunctions_1.GetDateTillLastWeek)(date, this.lang, this.dict);
        },
        getYearMonthString(date) {
            return (0, helperFunctions_1.GetYearMonthString)(date, this.lang);
        },
        getDayString(date) {
            return (0, helperFunctions_1.GetDayString)(date, this.lang);
        }
    },
});
exports.default = FilteredEventsOverview;
