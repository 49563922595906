"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const searchElements_1 = require("../searchElements");
const searchService_1 = require("../../services/searchService");
const SearchResultElement_vue_1 = require("./SearchResultElement.vue");
const SearchResultList = (0, vue_1.defineComponent)({
    name: "Search Results",
    props: {
        count: {
            type: Number,
            default: 20,
        }
    },
    data() {
        return {
            results: [],
            more: false,
            dict: this.dict,
            totalResults: 0,
            searchElement: new searchElements_1.SearchElement({}),
        };
    },
    async mounted() {
        this.searchElement = searchService_1.SearchService.LoadSearch(this.count);
        await this.loadResults(this.searchElement, false);
        this.emitter.on("changeSearch", async (result) => {
            if (result) {
                this.searchElement = result;
                await this.loadResults(result, false);
            }
        });
    },
    methods: {
        async loadMore(start) {
            const searchElement = new searchElements_1.SearchElement({
                searchTerm: this.searchElement.searchTerm,
                start: start,
                count: this.count
            });
            await this.loadResults(searchElement, true);
        },
        async loadResults(searchElement, add = true) {
            const searchResults = await searchService_1.SearchService.Search(searchElement);
            if (searchResults) {
                this.results = add ? this.results : [];
                this.more = searchResults.more;
                this.totalResults = searchResults.totalResults;
                this.results = [...new Set(this.results.concat(searchResults.results))];
                if (add) {
                    const newSearchElement = new searchElements_1.SearchElement({
                        searchTerm: searchElement.searchTerm,
                        count: this.results.length
                    });
                    searchService_1.SearchService.SaveSearch(newSearchElement);
                }
            }
        }
    },
    components: {
        SearchResultElement: SearchResultElement_vue_1.default
    },
});
exports.default = SearchResultList;
