"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NewsService = void 0;
const axios_1 = require("axios");
const news_1 = require("../filteredNewsEvents/news");
class NewsService {
    constructor() { }
    static async GetNewsElements(filteredRequest) {
        let newsResult = new news_1.NewsResult({});
        const apiUrl = "/umbraco/rentschler/newseventfilter/GetNewsElements";
        const response = await axios_1.default.get(apiUrl, {
            params: {
                filteredRequestString: JSON.stringify(filteredRequest)
            }
        });
        if (response && response.data) {
            newsResult = new news_1.NewsResult(response.data);
        }
        return newsResult;
    }
}
exports.NewsService = NewsService;
