"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchSuggestions = exports.SearchResult = exports.SearchResults = exports.SearchElement = void 0;
class SearchElement {
    searchTerm;
    start;
    count;
    constructor(item) {
        this.searchTerm = item.searchTerm ?? "";
        this.start = item.start ?? 0;
        this.count = item.count ?? 20;
    }
}
exports.SearchElement = SearchElement;
class SearchResults {
    results;
    more;
    totalResults;
    constructor(item) {
        this.more = item.more ?? false;
        this.totalResults = item.totalResults ?? 0;
        this.results = [];
        if (item.results && item.results.length > 0) {
            item.results.forEach(result => {
                this.results.push(new SearchResult(result));
            });
        }
    }
}
exports.SearchResults = SearchResults;
class SearchResult {
    title;
    description;
    url;
    key;
    constructor(item) {
        this.title = item.title ?? "";
        this.description = item.description ?? "";
        this.url = item.url ?? "";
        this.key = item.key ?? "";
    }
}
exports.SearchResult = SearchResult;
class SearchSuggestions {
    searchterm;
    suggestions;
    constructor(item) {
        this.searchterm = item.searchterm ?? "";
        this.suggestions = item.suggestions ?? [];
    }
}
exports.SearchSuggestions = SearchSuggestions;
