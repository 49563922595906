"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const helperFunctions_1 = require("../services/helperFunctions");
const TeaserCard = (0, vue_1.defineComponent)({
    name: "Teaser Card",
    props: {
        image: String,
        imageMobile: String,
        teaserText: String,
        url: String,
        alt: String,
        buttonText: {
            type: String,
            default: "Weiter",
        },
        date: Date,
    },
    methods: {
        getDate(date) {
            return (0, helperFunctions_1.GetDateString)(date);
        }
    }
});
exports.default = TeaserCard;
