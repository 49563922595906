"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.render = void 0;
const vue_1 = require("vue");
const _hoisted_1 = { class: "searchResultList" };
const _hoisted_2 = { class: "resultheadline" };
const _hoisted_3 = { key: 0 };
const _hoisted_4 = { key: 1 };
const _hoisted_5 = { class: "load-more" };
function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_search_result_element = (0, vue_1.resolveComponent)("search-result-element");
    return ((0, vue_1.openBlock)(), (0, vue_1.createElementBlock)(vue_1.Fragment, null, [
        (0, vue_1.createElementVNode)("div", _hoisted_1, [
            (0, vue_1.createElementVNode)("div", _hoisted_2, [
                (_ctx.searchElement.searchTerm && _ctx.searchElement.searchTerm.length > 0)
                    ? ((0, vue_1.openBlock)(), (0, vue_1.createElementBlock)("h1", _hoisted_3, (0, vue_1.toDisplayString)(_ctx.dict["Results"]) + " (" + (0, vue_1.toDisplayString)(_ctx.results.length) + " " + (0, vue_1.toDisplayString)(_ctx.dict["ResultsOf"]) + " " + (0, vue_1.toDisplayString)(_ctx.totalResults) + ")", 1 /* TEXT */))
                    : (0, vue_1.createCommentVNode)("v-if", true),
                (!_ctx.searchElement.searchTerm || _ctx.searchElement.searchTerm.length == 0)
                    ? ((0, vue_1.openBlock)(), (0, vue_1.createElementBlock)("h1", _hoisted_4, (0, vue_1.toDisplayString)(_ctx.dict["NoSearchtermResults"]), 1 /* TEXT */))
                    : (0, vue_1.createCommentVNode)("v-if", true)
            ]),
            ((0, vue_1.openBlock)(true), (0, vue_1.createElementBlock)(vue_1.Fragment, null, (0, vue_1.renderList)(_ctx.results, (result) => {
                return ((0, vue_1.openBlock)(), (0, vue_1.createBlock)(_component_search_result_element, { searchResult: result }, null, 8 /* PROPS */, ["searchResult"]));
            }), 256 /* UNKEYED_FRAGMENT */))
        ]),
        (0, vue_1.createElementVNode)("p", _hoisted_5, [
            (_ctx.more)
                ? ((0, vue_1.openBlock)(), (0, vue_1.createElementBlock)("button", {
                    key: 0,
                    class: "btn yellow",
                    onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.loadMore(_ctx.results.length)))
                }, (0, vue_1.toDisplayString)(_ctx.dict["LoadMore"]), 1 /* TEXT */))
                : (0, vue_1.createCommentVNode)("v-if", true)
        ])
    ], 64 /* STABLE_FRAGMENT */));
}
exports.render = render;
