"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const languageSwitch_vue_1 = require("./languageSwitch.vue");
const dictionaryService_1 = require("../services/dictionaryService");
const emitter_1 = require("../emitter/emitter");
const helperFunctions_1 = require("../services/helperFunctions");
const emitter = emitter_1.emitterInstance;
const languageSwitchApps = document.getElementsByClassName("langSwitchApp");
if (languageSwitchApps && languageSwitchApps.length > 0) {
    const language = (0, helperFunctions_1.getLanguage)();
    Array.from(languageSwitchApps).forEach((langApp) => {
        const selector = "#" + langApp.id;
        const currentPage = langApp.dataset["node"];
        const isoCode = langApp.dataset["iso"];
        const app = (0, vue_1.createApp)(languageSwitch_vue_1.default, {
            currentPage: currentPage,
            isoCode: isoCode
        });
        app.config.globalProperties.emitter = emitter;
        app.config.globalProperties.language = language;
        (0, dictionaryService_1.GetItems)("VUE_Language", language).then((dict) => {
            app.config.globalProperties.dict = dict;
            app.mount(selector);
        });
    });
}
