"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const newsFilter_1 = require("./newsFilter");
const closeButton_vue_1 = require("../layoutElements/closeButton.vue");
const newsEventFilterService_1 = require("../services/newsEventFilterService");
const NewsFilter = (0, vue_1.defineComponent)({
    name: "News Filter",
    props: {
        startNode: {
            type: String,
            default: "",
        }
    },
    data() {
        return {
            showOverlaySort: false,
            showOverlayType: false,
            showOverlayLocation: false,
            filterValue: new newsFilter_1.NewsFilterValue({}),
            filterSort: [{ name: this.dict["MostViews"], value: "views" }],
            filterType: [],
            filterLocation: [],
            dictionary: this.dict,
        };
    },
    async beforeMount() {
        await this.initFilter();
    },
    methods: {
        async initFilter() {
            this.filterValue = newsEventFilterService_1.NewsEventFilterService.LoadFilter(this.startNode);
            this.filterSort.unshift(new newsFilter_1.NewsFilterElement({ name: this.dict["Latest"], value: "" }));
            this.filterType = await newsEventFilterService_1.NewsEventFilterService.GetFilterTypes(this.startNode);
            this.filterLocation = await newsEventFilterService_1.NewsEventFilterService.GetFilterLocations();
        },
        toggleOverlay(type, value) {
            switch (type) {
                case "sorted":
                    this.showOverlaySort = value;
                    this.showOverlayType = false;
                    this.showOverlayLocation = false;
                    break;
                case "type":
                    this.showOverlaySort = false;
                    this.showOverlayType = value;
                    this.showOverlayLocation = false;
                    break;
                case "location":
                    this.showOverlaySort = false;
                    this.showOverlayType = false;
                    this.showOverlayLocation = value;
                    break;
            }
        },
        setValue(type, value) {
            this.filterValue[type] = value;
            this.emitter.emit("changeFilter", this.filterValue);
            this.toggleOverlay(type, false);
            newsEventFilterService_1.NewsEventFilterService.SaveFilter(this.startNode, this.filterValue);
        },
        setValues(values) {
            values.forEach((element) => {
                this.filterValue[element.type] = element.value;
                this.toggleOverlay(element.type, false);
            });
            this.emitter.emit("changeFilter", this.filterValue);
            newsEventFilterService_1.NewsEventFilterService.SaveFilter(this.startNode, this.filterValue);
        },
        getFilterName(type) {
            if (this.filterValue) {
                const value = this.filterValue[type];
                let list = [];
                switch (type) {
                    case "sorted":
                        list = this.filterSort;
                        break;
                    case "type":
                        list = this.filterType;
                        break;
                    case "location":
                        list = this.filterLocation;
                        break;
                }
                const element = list.find((element) => element.value == value);
                return element && element.name ? element.name : "";
            }
            return "";
        },
    },
    components: {
        CloseButton: closeButton_vue_1.default,
    },
});
exports.default = NewsFilter;
