"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("./components/newsFilter/newsFilterApp");
require("./components/filteredNewsEvents/filteredNewsOverviewApp");
require("./components/filteredNewsEvents/filteredEventsOverviewApp");
require("./components/homepageNewsEvents/homepageNewsOverviewApp");
require("./components/homepageNewsEvents/homepageEventsOverviewApp");
require("./components/search/searchbar/searchbarApp");
require("./components/search/searchresults/searchResultsApp");
require("./components/search/searchbarnatural/SearchbarNaturalApp");
require("./components/languageSwitch/languageSwitchApp");
