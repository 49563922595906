"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EventService = void 0;
const axios_1 = require("axios");
const event_1 = require("../filteredNewsEvents/event");
class EventService {
    constructor() { }
    static async GetEventElements(filteredRequest) {
        let eventsResult = new event_1.EventsResult({});
        const apiUrl = "/umbraco/rentschler/newseventfilter/GetEventElements";
        const response = await axios_1.default.get(apiUrl, {
            params: {
                filteredRequestString: JSON.stringify(filteredRequest)
            }
        });
        if (response && response.data) {
            eventsResult = new event_1.EventsResult(response.data);
        }
        return eventsResult;
    }
}
exports.EventService = EventService;
