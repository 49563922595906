"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NewsEventFilterService = void 0;
const axios_1 = require("axios");
const newsFilter_1 = require("../newsFilter/newsFilter");
const helperFunctions_1 = require("./helperFunctions");
class NewsEventFilterService {
    constructor() { }
    static async GetFilterLocations() {
        let locationFilterElements = [];
        const language = (0, helperFunctions_1.getLanguage)();
        const apiUrl = "/umbraco/rentschler/newseventfilter/getlocations";
        const response = await axios_1.default.get(apiUrl, {
            params: {
                language: language
            }
        });
        if (response && response.data) {
            locationFilterElements = response.data;
        }
        return locationFilterElements;
    }
    static async GetFilterTypes(startNode) {
        let locationFilterElements = [];
        const language = (0, helperFunctions_1.getLanguage)();
        const apiUrl = "/umbraco/rentschler/newseventfilter/GetFilterTypes";
        const response = await axios_1.default.get(apiUrl, {
            params: {
                startNode: startNode,
                language: language
            }
        });
        if (response && response.data) {
            locationFilterElements = response.data;
        }
        return locationFilterElements;
    }
    static LoadFilter(startNode) {
        let newsFilterValue = new newsFilter_1.NewsFilterValue({
            sorted: "",
            type: "",
            location: "",
            startNode: startNode,
        });
        var storageString = sessionStorage.getItem("newsEventFilter_" + startNode);
        if (storageString && storageString.length > 0) {
            newsFilterValue = new newsFilter_1.NewsFilterValue(JSON.parse(storageString));
        }
        return newsFilterValue;
    }
    static SaveFilter(startNode, newsFilterItem) {
        sessionStorage.setItem("newsEventFilter_" + startNode, JSON.stringify(newsFilterItem));
    }
    static LoadServiceElement(startNode, count, type) {
        let serviceElement = new newsFilter_1.NewsEventServiceElement({ count: count });
        var storageString = sessionStorage.getItem("newsEventServiceElement_" + type + "_" + startNode);
        if (storageString && storageString.length > 0) {
            serviceElement = new newsFilter_1.NewsEventServiceElement(JSON.parse(storageString));
        }
        return serviceElement;
    }
    static SaveServiceElement(startNode, type, serviceElement) {
        sessionStorage.setItem("newsEventServiceElement_" + type + "_" + startNode, JSON.stringify(serviceElement));
    }
}
exports.NewsEventFilterService = NewsEventFilterService;
