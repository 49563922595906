"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const newsFilter_1 = require("../newsFilter/newsFilter");
const teaserCard_vue_1 = require("../layoutElements/teaserCard.vue");
const eventService_1 = require("../services/eventService");
const helperFunctions_1 = require("../services/helperFunctions");
const HomepageEventsOverview = (0, vue_1.defineComponent)({
    name: "Homepage EventsOverview",
    props: {
        startNode: {
            type: String,
            default: "",
        },
        count: {
            type: Number,
            default: 8,
        },
        overviewNode: {
            type: String,
            default: "",
        }
    },
    data() {
        return {
            events: [],
            more: false,
            dictionary: this.dict,
            lang: this.language,
            overviewUrl: "#",
        };
    },
    components: {
        TeaserCard: teaserCard_vue_1.default,
    },
    async mounted() {
        await this.getEvents();
    },
    methods: {
        //add für reload vorbereiter
        async getEvents() {
            const filter = this.currentFilter;
            const filteredRequest = new newsFilter_1.FilteredRequest({
                startNode: this.startNode,
                start: 0,
                count: this.count,
                overviewNode: this.overviewNode,
                language: this.lang
            });
            const eventsResult = await eventService_1.EventService.GetEventElements(filteredRequest);
            const eventElements = [];
            this.events = [...new Set(eventElements.concat(eventsResult.events))];
            this.more = eventsResult.more;
            this.overviewUrl = eventsResult.overview;
        },
        getDateString(date) {
            return (0, helperFunctions_1.GetDateString)(date);
        },
        getPostDateString(date) {
            return (0, helperFunctions_1.GetDateTillLastWeek)(date, this.lang, this.dict);
        },
        getYearMonthString(date) {
            return (0, helperFunctions_1.GetYearMonthString)(date, this.lang);
        },
        getDayString(date) {
            return (0, helperFunctions_1.GetDayString)(date, this.lang);
        }
    },
});
exports.default = HomepageEventsOverview;
