"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchService = void 0;
const axios_1 = require("axios");
const searchElements_1 = require("../search/searchElements");
const helperFunctions_1 = require("./helperFunctions");
class SearchService {
    constructor() { }
    static LoadSearch(count = 20) {
        let searchElement = new searchElements_1.SearchElement({
            searchTerm: "",
            start: 0,
            count: count,
        });
        var storageString = sessionStorage.getItem("currentSearch");
        if (storageString && storageString.length > 0) {
            searchElement = new searchElements_1.SearchElement(JSON.parse(storageString));
        }
        return searchElement;
    }
    static SaveSearch(searchElement) {
        sessionStorage.setItem("currentSearch", JSON.stringify(searchElement));
    }
    static async Search(searchElement) {
        let searchResults = new searchElements_1.SearchResults({});
        const language = (0, helperFunctions_1.getLanguage)();
        const apiUrl = "/umbraco/rentschler/search/Search";
        const response = await axios_1.default.get(apiUrl, {
            params: {
                start: searchElement.start,
                count: searchElement.count,
                searchTerm: searchElement.searchTerm,
                language: language
            }
        });
        if (response && response.data) {
            searchResults = new searchElements_1.SearchResults(response.data);
        }
        return searchResults;
    }
    static async GetSuggestions(searchterm) {
        let suggestions = new searchElements_1.SearchSuggestions({});
        const language = (0, helperFunctions_1.getLanguage)();
        const apiUrl = "/umbraco/rentschler/search/GetSuggestions";
        const response = await axios_1.default.get(apiUrl, {
            params: {
                searchterm: searchterm,
                language: language
            }
        });
        if (response && response.data) {
            suggestions = new searchElements_1.SearchSuggestions(response.data);
        }
        return suggestions.suggestions;
    }
}
exports.SearchService = SearchService;
