"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class LanguageItem {
    langCode;
    isoCode;
    url;
    isSelected;
    constructor(item) {
        this.langCode = item.langCode ?? "";
        this.isoCode = item.isoCode ?? "";
        this.url = item.url ?? "";
        this.isSelected = item.isSelected ?? false;
    }
}
exports.default = LanguageItem;
