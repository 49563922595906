"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.render = void 0;
const vue_1 = require("vue");
const _hoisted_1 = { id: "filterApp" };
const _hoisted_2 = { class: "natural-language-main-list" };
const _hoisted_3 = { class: "natural-language-tools" };
const _hoisted_4 = { class: "natural-language-select" };
const _hoisted_5 = ["onClick"];
const _hoisted_6 = { class: "natural-language-tools" };
const _hoisted_7 = { class: "natural-language-select" };
const _hoisted_8 = ["onClick", "id", "data-node"];
const _hoisted_9 = { class: "natural-language-tools" };
const _hoisted_10 = { class: "natural-language-select" };
const _hoisted_11 = ["onClick"];
function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_close_button = (0, vue_1.resolveComponent)("close-button");
    return ((0, vue_1.openBlock)(), (0, vue_1.createElementBlock)("div", _hoisted_1, [
        (0, vue_1.createElementVNode)("div", {
            class: (0, vue_1.normalizeClass)(["news-filter", { 'visible': _ctx.showOverlayLocation || _ctx.showOverlaySort || _ctx.showOverlayType }])
        }, [
            (0, vue_1.createElementVNode)("ul", _hoisted_2, [
                (0, vue_1.createElementVNode)("li", null, [
                    (0, vue_1.createElementVNode)("a", {
                        href: "#",
                        onClick: _cache[0] || (_cache[0] = (0, vue_1.withModifiers)(($event) => (_ctx.toggleOverlay('sorted', true)), ["prevent"]))
                    }, (0, vue_1.toDisplayString)(_ctx.getFilterName("sorted")), 1 /* TEXT */)
                ]),
                (0, vue_1.createElementVNode)("li", null, [
                    (0, vue_1.createElementVNode)("a", {
                        href: "#",
                        onClick: _cache[1] || (_cache[1] = (0, vue_1.withModifiers)(($event) => (_ctx.toggleOverlay('type', true)), ["prevent"]))
                    }, (0, vue_1.toDisplayString)(_ctx.getFilterName("type")), 1 /* TEXT */)
                ]),
                (0, vue_1.createElementVNode)("li", null, (0, vue_1.toDisplayString)(_ctx.dictionary["From"]), 1 /* TEXT */),
                (0, vue_1.createElementVNode)("li", null, [
                    (0, vue_1.createElementVNode)("a", {
                        href: "#",
                        onClick: _cache[2] || (_cache[2] = (0, vue_1.withModifiers)(($event) => (_ctx.toggleOverlay('location', true)), ["prevent"]))
                    }, (0, vue_1.toDisplayString)(_ctx.getFilterName("location")), 1 /* TEXT */)
                ])
            ]),
            (_ctx.showOverlaySort)
                ? ((0, vue_1.openBlock)(), (0, vue_1.createElementBlock)("div", {
                    key: 0,
                    class: (0, vue_1.normalizeClass)(["natural-language-overlay sort", { 'active': _ctx.showOverlaySort }])
                }, [
                    (0, vue_1.createElementVNode)("div", _hoisted_3, [
                        (0, vue_1.createVNode)(_component_close_button, {
                            onClick: _cache[3] || (_cache[3] = ($event) => (_ctx.toggleOverlay('sorted', false)))
                        })
                    ]),
                    (0, vue_1.createElementVNode)("ul", _hoisted_4, [
                        ((0, vue_1.openBlock)(true), (0, vue_1.createElementBlock)(vue_1.Fragment, null, (0, vue_1.renderList)(_ctx.filterSort, (filter) => {
                            return ((0, vue_1.openBlock)(), (0, vue_1.createElementBlock)("li", {
                                onClick: ($event) => (_ctx.setValue('sorted', filter.value)),
                                class: (0, vue_1.normalizeClass)({ 'selected': _ctx.filterValue['sorted'] == filter.value })
                            }, (0, vue_1.toDisplayString)(filter.name), 11 /* TEXT, CLASS, PROPS */, _hoisted_5));
                        }), 256 /* UNKEYED_FRAGMENT */))
                    ])
                ], 2 /* CLASS */))
                : (0, vue_1.createCommentVNode)("v-if", true),
            (_ctx.showOverlayType)
                ? ((0, vue_1.openBlock)(), (0, vue_1.createElementBlock)("div", {
                    key: 1,
                    class: (0, vue_1.normalizeClass)(["natural-language-overlay type", { 'active': _ctx.showOverlayType }])
                }, [
                    (0, vue_1.createElementVNode)("div", _hoisted_6, [
                        (0, vue_1.createVNode)(_component_close_button, {
                            onClick: _cache[4] || (_cache[4] = ($event) => (_ctx.toggleOverlay('type', false)))
                        })
                    ]),
                    (0, vue_1.createElementVNode)("ul", _hoisted_7, [
                        ((0, vue_1.openBlock)(true), (0, vue_1.createElementBlock)(vue_1.Fragment, null, (0, vue_1.renderList)(_ctx.filterType, (filter) => {
                            return ((0, vue_1.openBlock)(), (0, vue_1.createElementBlock)("li", {
                                onClick: ($event) => (_ctx.setValues([{ type: 'type', value: filter.value }, { type: 'startNode', value: filter.key }])),
                                id: 'type_' + filter.value,
                                "data-node": filter.key,
                                class: (0, vue_1.normalizeClass)({ 'selected': _ctx.filterValue['type'] == filter.value })
                            }, (0, vue_1.toDisplayString)(filter.name), 11 /* TEXT, CLASS, PROPS */, _hoisted_8));
                        }), 256 /* UNKEYED_FRAGMENT */))
                    ])
                ], 2 /* CLASS */))
                : (0, vue_1.createCommentVNode)("v-if", true),
            (_ctx.showOverlayLocation)
                ? ((0, vue_1.openBlock)(), (0, vue_1.createElementBlock)("div", {
                    key: 2,
                    class: (0, vue_1.normalizeClass)(["natural-language-overlay location", { 'active': _ctx.showOverlayLocation }])
                }, [
                    (0, vue_1.createElementVNode)("div", _hoisted_9, [
                        (0, vue_1.createVNode)(_component_close_button, {
                            onClick: _cache[5] || (_cache[5] = ($event) => (_ctx.toggleOverlay('location', false)))
                        })
                    ]),
                    (0, vue_1.createElementVNode)("ul", _hoisted_10, [
                        ((0, vue_1.openBlock)(true), (0, vue_1.createElementBlock)(vue_1.Fragment, null, (0, vue_1.renderList)(_ctx.filterLocation, (filter) => {
                            return ((0, vue_1.openBlock)(), (0, vue_1.createElementBlock)("li", {
                                onClick: ($event) => (_ctx.setValue('location', filter.value)),
                                class: (0, vue_1.normalizeClass)({ 'selected': _ctx.filterValue['location'] == filter.value })
                            }, (0, vue_1.toDisplayString)(filter.name), 11 /* TEXT, CLASS, PROPS */, _hoisted_11));
                        }), 256 /* UNKEYED_FRAGMENT */))
                    ])
                ], 2 /* CLASS */))
                : (0, vue_1.createCommentVNode)("v-if", true)
        ], 2 /* CLASS */)
    ]));
}
exports.render = render;
