"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const searchElements_1 = require("../searchElements");
const SearchResultElement = (0, vue_1.defineComponent)({
    name: "Search Result",
    props: {
        searchResult: {
            type: searchElements_1.SearchResult,
            default: new searchElements_1.SearchResult({})
        }
    },
    data() {
        return {
            dict: this.dict,
        };
    }
});
exports.default = SearchResultElement;
